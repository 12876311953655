<template id="ciudades">
<div class="page-ciudades">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Configuración" class="elevation-1 px-5 py-3">
          <v-card-text>
            <v-container id="datagrid">
              <v-card-text>
                <v-form ref="form" lazy-validation>
                  <v-container grid-list-md>
                    <v-row>
                      <!--v-col sm="12" md="12" lg="4">
                        <v-text-field label="Meses Periodo de Stock" v-model="model.meses_periodo_stock" :rules="[rules.required, rules.entero]"></v-text-field>
                      </v-col-->
                      <v-col sm="12" md="12" lg="4">
                        <v-text-field label="Margen de Redondeo" v-model="model.redondeo" :rules="[rules.required, rules.decimal]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click.native="guardar()">
                  <v-icon>done</v-icon> {{"Guardar" }}
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>


<script>
export default {
  mounted: function() {
    if (!this.verificaPermiso('t.utilerias.configuracion')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.get_configuracion();
  },
  data() {
    return {
      id_configuracion: "",
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: ""
        },
        {
          text: "Configuración",
          disabled: true,
          href: ""
        }
      ],
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
    }
  },
  methods: {
    clean_model: function() {
      return {
        meses_periodo_stock: 0,
        redondeo: 0
      };
    },
    get_configuracion: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {
          "type": "configuracion"
        },
        "fields": ["_id", "meses_periodo_stock", "redondeo"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          this.id_configuracion = response.data.docs[0]._id;
          this.model.meses_periodo_stock = response.data.docs[0].meses_periodo_stock != undefined ? response.data.docs[0].meses_periodo_stock : 0;
          this.model.redondeo = response.data.docs[0].redondeo != undefined ? response.data.docs[0].redondeo : 0;
          window.dialogLoader.hide();
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    guardar: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_configuracion/_update/update_configuracion/" + this.id_configuracion;
        let data = {
          meses_periodo_stock: this.model.meses_periodo_stock,
          redondeo: this.model.redondeo
        };

        window.axios
          .post(url, data)
          .then(response => {
            if (response.data == true) {
              this.modal = false;
              this.get_configuracion();
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al guardar el registro.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      }
    }
  }
}
</script>
